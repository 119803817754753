import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { VCard } from 'ngx-vcard';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Perfil } from '../interface/perfil';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {  

  identificador: string = '';
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  preview = '';
  imageInfos?: Observable<any>;  
  profile!: Perfil;
  vcard!: VCard;
  

  t1: string= '';
  t2: string= '';
  t3: string= '';
  Swal: any;

  //Button action  
  visible:boolean = true;
  cargando: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private store: AngularFirestore,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,  
  
  ) {   
    this.identificador = this.route.snapshot.params['identificador'];
  }

  ngOnInit(): void { 

      this.cargando = true;
      
      const isLogin = this.authService.isLoggedIn;
      if(isLogin){

        const user = this.authService.user;

        if(user.email.split('@')[0] != this.identificador){
          Swal.fire({
            title: '¡Error!',
            text: 'Acceso no autorizado.',
            imageUrl: './assets/alert-circle.png',
            imageWidth: 46,
            imageHeight: 45,
            imageAlt: 'imagen error',
          });
         
        setTimeout(() =>{this.cerrarSesion();}, 1000);                        
        }
        else{
          
          this.onclick();
        
        }       
      }
      else{
        this.router.navigate(['']);
      }
  }  

 
  onclick(){
    this.visible = !this.visible
    this.spinner.show();      
    this.identificador = this.route.snapshot.params['identificador'];
    this.cargando= true;
   //Buscar en la base de datos
    if( this.identificador !== undefined){  
     //Buscar Perfil
     const perfilRef = this.store.collection('colaboradores', ref => ref.where('slug', '==', String( this.identificador ) ))
     .get()
     .subscribe(DocumentSnapshot => {
       if(DocumentSnapshot.docs.length > 0){
        console.table(DocumentSnapshot.docs[0].data());

         this.profile = DocumentSnapshot.docs[0].data() as Perfil;
         //Cargando datos
          this.t1 = this.profile.mobilePhone || '';
          this.t2 = this.profile.mobilePhone2 || '';
          this.t3 = this.profile.mobilePhone3 || '';
          this.spinner.hide(); 
          this.cargando=false; 
       }
       else{
         this.router.navigate([''])
       }
     });
   }   
  } 
  //

  //subir imagen
  selectFile(event: any): void {   

    this.spinner.show();

    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;
  
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
  
      if (file) {
        this.preview = '';
        this.currentFile = file;
  
        const reader = new FileReader();
  
        reader.onload = (e: any) => {
          const file = event.target.files[0];
          const extension = file.type;
          const filePath = this.profile.slug + '.' + extension.replace(/(.*)\//g, '');
          const ref = this.storage.ref(filePath);
          const task = ref.putString(e.target.result,"data_url", {contentType: extension }).then((snapshot) =>{
            snapshot.ref.getDownloadURL().then(downloadURL => {
              const imageUrl = downloadURL;
              this.preview = imageUrl;
              this.spinner.hide();
            });
          });
        };
  
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  saveProfile(){    

    const doc = this.store.collection('colaboradores').doc(this.profile.mail);
    
    this.spinner.show();
    //Actualizacion de datos      
    doc.update({
      dateUpdate: new Date(),
      mobilePhone: this.t1,
      mobilePhone2: this.t2,
      mobilePhone3: this.t3,
    }).then(result => {

      //Actualizacion de imagen
      if(this.preview != ''){
        doc.update({
          'profilePhoto': this.preview})
          .then(result => {
            this.spinner.hide();
            Swal.fire({     
              title: '¡Los datos se han <br> guardado correctamente!',        
              imageUrl: './assets/checkmark-circle.png',
              imageWidth: 46,
              imageHeight: 45,
              imageAlt: 'imagen error',
              timer: 2000,
            });
          });
      }
      
      else{
        this.spinner.hide();             
        Swal.fire({     
          title: '¡Los datos se han <br> guardado correctamente!',        
          imageUrl: './assets/checkmark-circle.png',
          imageWidth: 46,
          imageHeight: 45,
          imageAlt: 'success',
          timer: 2000,
        });
        
      }
    });
  }


  cerrarSesion(){
    this.authService.SignOut().then(result => {
      this.router.navigate(['']);
    });
  }

}
