<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container-login-profile">
    <div class="box-login-profile">
      <div class="row-login-profile"> 
        <img class="logo-divi" src="./assets/logo-dive.png" style="width: 150px;">
        <img class="logo-andes" src="./assets/logo-andesmotor.png" style="width: 170px; display: none;">
        <p>Iniciar sesión</p>
        <img class="logo-padlock" src="./assets/cerradura-icon.png" style="width:160px; margin-bottom: 50px; margin-top: 20px;">        
        <section class="botones-login">
            <button class="button-microsoft" (click)="iniciarSesion()"> <img src="./assets/icon-microsoft.png" style="width: 16px;">Acceder con Microsoft</button>                 
        </section>       
       </div>
    </div>
</div>