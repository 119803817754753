<div class="not-found">
    <div class="container top-adv">
        <h2>Oops!</h2>           
        <img  class="dive" src="./assets/logo-not-found.png">
        <img  class="andes" src="./assets/404-andes.png">       
        <h1>404</h1>
        <p>Página no encontrada</p>
    </div>
</div>

