import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {


  search: string = "";

  perfiles: Array<any> = [];
  perfilesFilter: Array<any> = [];

  constructor(
    private store: AngularFirestore,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this.spinner.show();

    const perfilRef = this.store.collection('colaboradores')
    .get()
    .subscribe(DocumentSnapshot => {
      
      const perfiles = DocumentSnapshot.docs.map((doc) => doc.data());

      this.perfiles = perfiles;
      this.perfilesFilter = perfiles;

      this.spinner.hide();

    });

  }


  filtrar(){
    const filtrados = this.perfiles.filter(perfil => perfil.slug.includes(this.search) ||  perfil.displayName.includes(this.search));
    this.perfilesFilter = filtrados;
  }

}
