<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div style="
padding: 20px;
background: white;
border-radius: 16px;
"
*ngIf="perfiles.length > 0"
>
    <h1>Perfiles ({{ perfiles.length }})</h1>
    <input type="text" placeholder="Filtrar por nombre o slug" [(ngModel)]="search" (input)="filtrar()">
    <p *ngFor="let perfil of perfilesFilter">
        ({{ perfil.company}}) {{ perfil.displayName }} <a target="_blank" [href]="'https://vcard.demo.gimalca.com/' +perfil.slug">vCard</a>
    </p>
</div>