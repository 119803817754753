<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="main-perfil">
  <div class="content-box">  
    <div class="container">
        <section class="seccion seccion-profil" *ngIf="profile">
          <div class="img-profile-default box-top-img">
            <div id="box-img-perfil" style="background-image: url( {{profile.profilePhoto}} );" ></div>
          </div>
          <div class="box-top-datos">
            <div class="datos-perfil">             
              <h1> {{profile.displayName}}</h1>
              <h2> {{profile.jobTitle}}</h2>
              <h3> {{profile.company}}</h3> 
              
              <!--CONTENIDO QR-->
              <div class="seccion-img-qr" *ngIf="ReadMore && URLactual" >            
                <ngx-kjua 
                  text = "{{ URLactual }}"
                  [size]="200"
                  ecLevel = "Q"
                  [quiet]="3"
                  [cssClass]="'border-radius'"  
                ></ngx-kjua>
                    <!--Close window button qr  -->
                    <div class="close-button" (click)="onclick()">
                      <img src="./assets/cerrar.png" style="width: 16px; height: 16px;">
                    </div>          
              </div>
              <!-- //FIN QR-->
              <div class="seccion-icon-contacto "  *ngIf="visible">
                <a href="mailto:{{profile.mail}} "><li class="icon-list-profil">
                  <img src="./assets/icon-mail-x3.png" style="margin: 20px; height: 20px;">
                </li>
                </a>
                <a href="tel:{{profile.mobilePhone}}" *ngIf="profile.mobilePhone"><li class="icon-list-profil">
                  <img src="./assets/icon-mobile-x3.png" style="width: 17.75px; height: 30.03px;">
                </li>
                </a>
                <a href="sms:{{profile.mobilePhone}}?&body=" *ngIf="profile.mobilePhone">
                <li class="icon-list-profil">
                  <img src="./assets/icon-sms-x3.png" style="width: 25.57px; height: 23.74px;">
                </li>
                </a>
                <a href="https://api.whatsapp.com/send?phone=51{{profile.mobilePhone}}" target="_blank" *ngIf="profile.mobilePhone">
                  <li class="icon-list-profil">
                    <img src="./assets/whatspp-icon-white.png" style="width: 25.57px; height: 25.74px;">
                  </li>
                </a>
              </div>        
            </div>
          </div>
        </section>
      </div>    

      <div class="container-datos-contacto"  *ngIf="visible && profile">
        <section>
          <div class="row-header-text">
            <div class="row-img-contacto">
              <img src="./assets/icon-mobile-x3.png" style="width: 17.75px; height: 30.03px;"> 
            </div>
            <div class="row-text-contacto">
            <h4> Contacto </h4>
            </div>
          </div> 
          <hr>    
          <div class="contendor-datos-perfil">
            <div class="row-mobil" *ngIf="profile.mobilePhone || profile.businessPhones.length > 0">
              <h5>Teléfono<span *ngIf="(profile.businessPhones.length > 0 && profile.mobilePhone) || (profile.mobilePhone  && profile.mobilePhone2)">s</span></h5>
              <span *ngIf="profile.businessPhones.length > 0"><p>{{profile.businessPhones[0] }}</p></span>
              <span *ngIf="profile.mobilePhone"><p>+51 {{profile.mobilePhone}}</p></span>
              <span *ngIf="profile.mobilePhone2"><p>+51 {{profile.mobilePhone2}}</p></span>
              <span *ngIf="profile.mobilePhone3"><p>+51 {{profile.mobilePhone3}}</p></span>
            </div>
            <div class="row-email" [hidden]="profile.mail == null">
              <h5>Email</h5>
              <span><p> {{profile.mail}} </p></span>
            </div>
            <div class="row-direction" [hidden]="profile.streetAddress == null">
              <h5>Dirección</h5>
              <span><p>{{profile.streetAddress}} </p></span>
            </div>
            <div [hidden]="profile.streetAddress == null" >
              <a href="https://www.google.com/maps/place/{{profile.streetAddress}}" 
                target="_blank" class="button-direction" > 
                <img src="./assets/icon-direction.png">
                <h6>Dirección</h6>
              </a>
            </div>  
          </div> 
          
        </section>
    </div>

    <div class="container-red-social"  *ngIf="visible  && profile">
      <section>     
        <div class="row-text-red-social">
          Redes Sociales
        </div>    
          <hr>    
        <div class="row-red-social-icon dive">
            <div class="box-icon-red-social">
              <a href="https://www.facebook.com/divemotor" target="_blank"><img src="./assets/icon-face.png" style="width: 40px; height: 40px;"></a>
            </div>
            <div class="box-icon-red-social">
              <a href="https://www.linkedin.com/company/divemotor/" target="_blank"><img src="./assets/icon-in.png" style="width: 40px; height: 40px;"></a>
            </div>
            <div class="box-icon-red-social">
              <a href="https://www.divemotor.com/" target="_blank"><img src="./assets/icon-web.png" style="width: 40px; height: 40px;"></a>
            </div>        
        </div>
        <div class="row-red-social-icon andes">
          <!--<div class="box-icon-red-social">
            <a href="#"><img src="./assets/facebook-andes.png" target="_blank" style="width: 40px; height: 40px;"></a>
          </div>-->
          <div class="box-icon-red-social">
            <a href="https://www.linkedin.com/company/andes-motor-peru/" target="_blank"><img src="./assets/logotipo-de-linkedin-andes.png" style="width: 40px; height: 40px;"></a>
          </div>
          <div class="box-icon-red-social">
            <a href="https://www.andesmotor.pe/" target="_blank"><img src="./assets/red-mundial-andes.png" style="width: 40px; height: 40px;"></a>
          </div>        
      </div>
              
      </section>
    </div>
  </div>
    <!--Botones Flotantes-->
    <div class="main-botones-flotantes"  *ngIf="visible && profile">
        <div class="container-boton-qr" (click)="onclick()" >
          <div class="boton-qr">
            <img src="./assets/icon-qr-code-x3.png" >
          </div>
        </div>

        <div class="container-add-contact" >
          <div class="add-contact-box" (click)="generatevCard()">
              <div class="add-contact-tex">
                  Agregar Contacto
              </div>
              <div class="add-contact-img">
                  <img src="./assets/x-x3-icon.png" style="width: 32px; height: 32px;">
              </div>
          </div>
        </div>

        <div class="container-share">
          <div class="boton-to-share" (click)="onclickShare()">
            <img src="./assets/compartir.png">
          </div>
        </div>
        
         <!--   <div class="container-download-home" *ngIf="visible && profile">
                <div class="boton-d-home" (click)="onclickHome()">
                  <img src="./assets/hogar.png">
                </div>
            </div> 

            -->
      </div>


    <!--- FOOTER MOBILE-->
    <div  class="footer-mobile" style="display: none;" *ngIf="visible && profile">
      <div class="footer-row">
        <div class="footer-box-icons">
          <div class="boton-qr" (click)="onclick()">
            <img src="./assets/icon-qr-code-x3.png" >
          </div>     
          <div class="footer-box-to-share" (click)="onclickShare()">
            <img src="./assets/compartir.png" style="width: 25px; height: 25px;">     
          </div>
          
          <div *ngIf="showButton" class="footer-box-dw-home" (click)="onclickHome()" >
            <img src="./assets/hogar.png" style="width: 25px; height: 25px;">
          </div>

          <div *ngIf="!showButton &&  platform.IOS" class="footer-box-dw-home" (click)="onclickHome2()" >
            <img src="./assets/hogar.png" style="width: 25px; height: 25px;">
          </div>
        </div> 
        
          <div>
          <div class="add-contact-box" (click)="generatevCard()">
                <div class="add-contact-tex">
                    Agregar Contacto
                </div>
                <div class="add-contact-img">
                    <img src="./assets/x-x3-icon.png" style="width: 32px; height: 32px;">
                </div>
            </div>
          </div>  
        </div>
    </div>
</div>