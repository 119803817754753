import { Component, HostListener, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EmailValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { VCard } from 'ngx-vcard';
import { Perfil } from '../interface/perfil';
import Swal from 'sweetalert2';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent {


  favIcon: HTMLLinkElement = document.querySelector('#appIcon'); 
  appTitle: HTMLTitleElement = document.querySelector('#appTitle'); 
  appManifest: HTMLLinkElement = document.querySelector('#my-manifest-placeholder');

  //
  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();
  
  profile!: Perfil;
  vcard!: VCard;
  URLactual = window.location;
 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: AngularFirestore,
    private spinner: NgxSpinnerService,
    public platform: Platform,
    )
  
    {

    const identificador = this.route.snapshot.params['identificador'];

    this.spinner.show();

    //Buscar en la base de datos
    if( identificador !== undefined){
      
      //Buscar Perfil
      const perfilRef = this.store.collection('colaboradores', ref => ref.where('slug', '==', String( identificador ) ))
      .get()
      .subscribe(DocumentSnapshot => {
        if(DocumentSnapshot.docs.length > 0){
          this.profile = DocumentSnapshot.docs[0].data() as Perfil;

          //Validando Dominio
          if(this.profile.company == 'Divemotor' && this.URLactual.hostname.includes('andesmotor')){
              window.location.replace(environment.vcarddivemotor + '/' + this.profile.slug);
          }

          if(this.profile.company == 'Andesmotor' && this.URLactual.hostname.includes('divemotor')){
            window.location.replace(environment.vcardandesmotor + '/' + this.profile.slug);
          }

          //Cambiando Title
          this.appTitle.text = this.profile.displayName + " - " + this.profile.company;
          this.favIcon.href = this.profile.profilePhoto;

          //Cargando VCARD
          this.vcard =  {         
            name: {            
              firstNames: this.profile.displayName,
              lastNames:  ' - ' + this.profile.company,
              },
              role: this.profile.jobTitle,
              organization: this.profile.company,
              email: [this.profile.mail],
              geoPosition: this.profile.streetAddress,
              telephone: [
                this.profile.mobilePhone,
                this.profile.mobilePhone2!,
                this.profile.mobilePhone3!,
              ]  
            };
           
            this.spinner.hide();    
            
            this.generateManifest();
 
        }
        else{
          this.router.navigate([ identificador + '/no-encontrado'])
        }
      });
    }  
  }
   ReadMore:boolean = false 
   visible:boolean = true

  onclick(){
    this.ReadMore = !this.ReadMore
    this.visible = !this.visible
  } 

  onclickShare(){

    const { title, text, url } = {title:"Compartir contacto", text: this.profile.displayName , url: this.URLactual.toString() };
    const toBeShared = {
      title,
      text,
      url
    };
    if (navigator.share) {
      navigator
        .share({
          ...toBeShared
        })
        .then(() => console.log("Share was successful."))
        .catch((error: DOMException) =>
          console.log(
            `Sharing failed! Code: ${error.code} | Name: ${
              error.message
            } | Message: ${error.message}`
          )
        );
    } else {

      navigator.clipboard.writeText(this.URLactual.toString());

      Swal.fire({     
        title: 'El enlace fue copiado al portapapeles',        
        imageUrl: './assets/checkmark-circle.png',
        imageWidth: 46,
        imageHeight: 45,
        imageAlt: 'success',
        timer: 2000,
      });
    }


  }


  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: { preventDefault: () => void; }) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }


  onclickHome() {
    // hide our user interface that shows our A2HS button
    //this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
    .then((choiceResult: { outcome: string; }) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt');
    }
    this.deferredPrompt = null;
  })
}

onclickHome2(){
  Swal.fire({
    html:
    '<p>Agregar a inicio<br>(enlace directo)</p><img src="./assets/new-install-ois.jpg" style="width: 100%;">',    
    imageAlt: 'Install iOS',   
    showCloseButton: true,
  })
}

generateManifest(){
  this.appManifest.href = 'https://'+ this.URLactual.hostname + '/api/manifest.php?title=' + this.profile.displayName + '&id=' + this.profile.slug + '&profileimage=' + encodeURIComponent(this.profile.profilePhoto);
}

generatevCard(){
  
let vcardfile = `BEGIN:VCARD
VERSION:3.0
N:Apellido;Nombre
FN:Nombre Apellido
ORG:Compañia
TITLE:Cargo
item1.TEL;type=Móvil:+51 123456789
item1.X-ABLabel:Móvil
item2.EMAIL;type=Email:usuario@divemotor.com.pe
item2.X-ABLabel:Email
item3.ADR;X-Dirección:;;Av. Canadá 1160, La Victoria 15034, Peru;Lima;Municipalidad Metropolitana de Lima;15086;Perú
item3.X-ABLabel:Dirección
item3.X-ABADR:perú
item4.URL;type=linkedin:https://www.linkedin.com/
item4.X-ABLabel:Linkedin
item5.URL;type=facebook:
item5.X-ABLabel:Facebook
END:VCARD`;

  const blob = new Blob([vcardfile], { type: 'text/vcard' });
  fs.saveAs(blob, `${this.profile.displayName}.vcf`);
}


}
