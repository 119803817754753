<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container-login-profile" *ngIf="!cargando">
    <div class="box-login-profile">

    <div class="main-edit-profile" *ngIf="!visible">
        <section class="container-edit-profile"><div>
                <div id="box-edit-img-perfil">
                    <img [src]="preview || profile.profilePhoto" class="preview">
                </div>
                <div class="icon-edit-img">
                    <label class="booton-subir-imagen">
                        <input type="file"  name="src-file2" accept="image/*" (change)="selectFile($event)" />       
                   </label>
                </div>
            </div>
        </section>
        <section>
            <p>{{profile.displayName}}</p>
        </section>
        <section class="customizable-fields">
            <div class="box-customizable-field">
                <h2>Editar datos:</h2>                                        
            </div>          
        </section>

        <div class="change-field">
            <div class="box-telephone">            
                <label for="txt-state">Teléfono Principal:</label>
                <input class="telephone"  type="text" name="telephone" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="9" minlength="5" required="required" [(ngModel)]="t1">
            </div>
            <div class="box-telephone"> 
                <label for="txt-state">Teléfono adicional 1:</label>
                <input class="telephone"  type="text" name="telephone2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="9" minlength="5" required="required" [(ngModel)]="t2">
            </div>
            <div class="box-telephone">  
                <label for="txt-state">Teléfono adicional 2:</label>
                <input class="telephone"  type="text" name="telephone3" onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="9" minlength="5" required="required" [(ngModel)]="t3">
             </div>
             <div class="button-save">
                <button (click)="saveProfile()">Guardar Cambios</button>
             </div>             
        </div>
        <div class="button-close">
         <button class="button-close" (click)="cerrarSesion()">Cerrar Sesión</button>
        </div>
</div> 
    </div>
</div>