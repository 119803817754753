import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectoryComponent } from './directory/directory.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PerfilComponent } from './perfil/perfil.component';

const routes: Routes = [

  { path: '', component: LoginComponent },
  
  { path: 'directorio', component: DirectoryComponent},
  
  { path: ':identificador', component: PerfilComponent },

  { path: ':identificador/no-encontrado', component: NotFoundComponent },

  { path: ':identificador/edit', component: EditProfileComponent},
  
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
