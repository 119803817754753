import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as auth from 'firebase/auth';

import  *  as CryptoJS from  'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any; // Save logged in user data
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, 
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('e3ef78dc582eeae9e5803efff8e481e6', CryptoJS.AES.encrypt( JSON.stringify(this.userData) , 'securekey' ).toString() );
        localStorage.setItem('832f8bbcbc9ac4c4b666301a79a428a9', CryptoJS.AES.encrypt( JSON.stringify({fakekey : 'fakevalue' }) , 'securekey' ).toString() );
        localStorage.setItem('c432680dc29946717230db7398fa5129', CryptoJS.AES.encrypt( JSON.stringify({fakekey2 : 'fakevalue2' }) , 'securekey' ).toString() );
      } else {
        localStorage.setItem('e3ef78dc582eeae9e5803efff8e481e6', 'null');
        localStorage.setItem('832f8bbcbc9ac4c4b666301a79a428a9', 'null');
        localStorage.setItem('c432680dc29946717230db7398fa5129', 'null');
      }
    });
  }


  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('e3ef78dc582eeae9e5803efff8e481e6');
    return user !== 'null';
  }

  // Returns true when user is looged in and email is verified
  get user(): any {
    const decodeValue = CryptoJS.AES.decrypt( localStorage.getItem('e3ef78dc582eeae9e5803efff8e481e6')! , 'securekey' ).toString(CryptoJS.enc.Utf8) ;
    const user = JSON.parse( decodeValue );
    return user;
  }


  // Sign in with Microsoft
  MicrosoftAuth() {
    return this.AuthLogin(new auth.OAuthProvider('microsoft.com')
    .setCustomParameters({tenant: 'e326d54a-d79f-422f-8253-f4278c0b97b3'}))
    .then((res: any) => {
      //Do nothing
    });
  }


  // Auth logic to run auth providers
  AuthLogin(provider: any) {

    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.afAuth.idTokenResult.subscribe((result) => {
          return result;
        })
      })
      .catch((error) => {
        console.log('Verifique sus credenciales.');
      });
  }



  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
    });
  }
}
