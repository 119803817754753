import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  
  company: string = "divemotor-style";


  constructor(public router: Router){
    this.company = window.location.href.includes('andesmotor') ? 'andesmotor-style':'divemotor-style';

    //Enable GA4 on Production
    if(environment.production){
      this.setUpAnalytics();
    }

  }


  setUpAnalytics() {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'G-XT3FGSZ3M4', {
          'page_path': event.urlAfterRedirects,
          'page_title': event.urlAfterRedirects
        });
       }
    })
    }

}
