import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
  }

  iniciarSesion(){
    this.authService.MicrosoftAuth().then(result => {
      this.spinner.show();
      setTimeout(() => {
        let identificador = this.authService.user.email.split('@')[0];
        this.router.navigate([identificador + '/edit']);
      }, 2000);
      

    }).catch((error) => {
      console.log('Verifique sus credenciales.');
    });;
  } 

}
